@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@700&family=Inter:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
    position: relative;
}

@keyframes floating {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0);
    }
}

.floating {
    animation: floating 5s ease-in-out infinite;
}

@keyframes glow {
    0% {
        filter: drop-shadow(0 0 2px #2014A9) drop-shadow(0 0 10px #6dc5da);
    }

    50% {
        filter: drop-shadow(0 0 40px #2014A9) drop-shadow(0 0 80px #6dc5da);
    }

    100% {
        filter: drop-shadow(0 0 2px #2014A9) drop-shadow(0 0 10px #6dc5da);
    }
}

.glow {
    animation: glow 8s ease-in-out infinite;
}

@keyframes shiny {
    0% {
        transform: translate(calc(-50% - 24px), -50%) rotate(0deg);
    }

    50% {
        transform: translate(calc(-50% - 24px), -50%) rotate(180deg);
    }

    100% {
        transform: translate(calc(-50% - 24px), -50%) rotate(360deg);
    }
}

@keyframes opacity {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
}

.shiny {
    animation: shiny 60s linear infinite, opacity 5s ease-in-out infinite;
}

.radius {
    &::before {
        position: absolute;
        display: block;
        content: "";
        top: -15%;
        right: 1%;
        background: radial-gradient(50% 50% at 50% 50%, rgba(35, 115, 235, 0.5) 0%, rgba(32, 20, 169, 0.0182292) 100%);
        width: 136%;
        padding-top: 136%;
        opacity: 0.7;
    }
}

.text_glow {
    text-shadow: 0px 0px 6px #7FC3C8;
}

.unit {
    background-repeat: no-repeat;
    background-position: 4px 12px;
    counter-increment: step-counter;

    a {
        &::before {
            position: absolute;
            display: block;
            content: attr(data-index);
            color: #83F0F8;
            font-size: 48px;
            font-family: "Inter";
            top: 0;
            left: 50%;
            transform: translateX(calc(-50% - 115px));
            font-weight: 400;
        }
    
        &::after {
            position: absolute;
            display: block;
            content: "";
            top: 4px;
            left: 50%;
            background-color: #83F0F8;
            height: 62px;
            width: 2px;
            transform-origin: center center;
            transform: translateX(calc(-50% - 75px)) rotate(45deg);
        }
    }

    &.order:first-child {
        a {
            &::before {
                counter-set: step-counter 5;
            }
        }
    }
}

.unit_image {
    box-shadow: 0px 0px 12px 0px #FFF;
}

@media only screen and (min-width: 1024px) {
    .unit {
        a {
            &::before {
                left: 0;
                transform: translateX(0);
            }
        
            &::after {
                left: 54px;
                transform: translateX(0) rotate(45deg);
            }
        }
    }
}